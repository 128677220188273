<template>
  <div  @mousewheel='roll' :style="styleVar">
    <div class="box" ref="box">
      <p class="p1">物联网数字经济</p>
      <p class="p2">智能安全溯源平台</p>
        <img src="../assets/images/logo.png" alt="" class="logo">
        <ul class="ul">
          <li class="li" v-for="(item,index) in content" :key="index" @click="toOther(item.path)">
            {{item.name}}
          </li>
        </ul>
        <img  src="../assets/images/home.png" class="img">
    </div>
    <takeawaySystem ref="takeawaySystem"></takeawaySystem>
    <sealLock ref="sealLock"></sealLock>
    <electroCar ref="electroCar"></electroCar>
    <safetyBox ref="safetyBox"></safetyBox>
    <download ref="download"></download>
    <about ref="about"></about>
    <img src="@/assets/images/goTop.png"  class="toTop" v-show="show" @click="toTop" title="回到顶部">
  </div>
</template>

<script>

import takeawaySystem from "../components/takeawaySystem.vue";
import sealLock from "../components/sealLock.vue";
import electroCar from "../components/electroCar.vue";
import safetyBox from "../components/safetyBox.vue";
import download from "../components/download.vue";
import about from "../components/about.vue";

export default {
  components: {
    takeawaySystem,sealLock,electroCar,safetyBox,download,about
  },
  data() {
    return {
      content: [{name:"外卖管理系统",path:"takeawaySystem"},
      {name:"智能封签锁",path:"sealLock"}, 
      {name:"智能网络餐饮电动车",path:"electroCar"}, 
      {name:"智能网络餐饮安全箱",path:"safetyBox"},
      {name:"APP下载",path:"download"},
      {name:"关于我们",path:"about"}
      ],
      show:false,
      htmlsize:""
      }
  },
  methods:{
    toOther(path){
      console.log(path);
      this.$refs[path].$refs[path].scrollIntoView(true);
      this.show=true
    },
    toTop(){
      this.$refs["box"].scrollIntoView(true);
      this.show=false
    },
    roll(e){
      if(e.pageY>1000){
        this.show=true
      }else{
         this.show=false
      }
    }

  },
  created(){
    
  },
  mounted(){
    this.htmlsize=getComputedStyle(window.document.documentElement)['font-size'].replace ("px","")
   
    console.log(this.htmlsize/192);
  },
   computed: {
        styleVar() {
            return {
                 '--shrink': this.htmlsize/192 ,     // 必须是px  rpx出错  
                
            }
        }
    },
  
}
</script>

<style  scoped>
 .img{
    width: 1920px;
  }
  .logo{
    position: absolute;
    width: 283px;
    height: 67px;
    left: 150px;
    top: 80px;
  }
  .p1{
    position: absolute;
    left: 200px;
    top: 280px;
    font-size: 62px; 
    color: #ef8200;
    letter-spacing: 6px;
  }
  .p2{
     position: absolute;
    left: 200px;
    top: 380px;
    font-size: 62px;
    color: #ef8200;
    letter-spacing: 6px;
  }
  .ul{
    list-style: none;
    position: absolute;
    display: flex;
    left: 580px;
    top: 65px;
  }
  .li{
    cursor: pointer;
    font-size: 24px;
    margin-left: 45px;
    border-radius: 5px;
    color: white;
  }
  .li:hover{
    background-color:#00FFFF ;
  }
  .li:active{
    background-color: #F0F8FF;
  }
  .toTop{
    position: fixed;
    top: 800px;
    right: 50px;
    width: 80px;
  }
  @media screen and (max-width: 500px) { 
    *{
      margin: 0;
      padding: 0;
    }
   .ul{
    list-style: none;
    position: absolute;
    display: flex;
    left: 0px;
    top: 30px;
    align-items: center;
  }
  .li{
    cursor: pointer;
    font-size: 24px;
    margin-left: 45px;
    border-radius: 5px;
    color: black;
    transform: scaleY(0.8);
  }

  }
    @media screen and (min-width: 500px) and (max-width: 800px) { 
      *{
        margin: 0;
        padding: 0;
      }
    .ul{
      list-style: none;
      position: absolute;
      display: flex;
      top: 30px;
      align-items: center;
    }
  }
     @media screen and (min-width: 800px) and (max-width: 900px) { 
      *{
        margin: 0;
        padding: 0;
      }
    .ul{
      list-style: none;
      position: absolute;
      display: flex;
      top: 80px;
      align-items: center;
    }
  }
</style>


