<template>
    <div ref="safetyBox" class="safetyBox">
        <p>智能网络餐饮安全箱</p>
        <img src="@/assets/images/safetyBox.png" alt="">
    </div>
</template>


<script>
export default {
    data(){
        return{

        }
    },
    created(){
        // console.log(this.$parent.$refs);
    }

}
</script>

<style>

</style>

<style scoped>
    .safetyBox{
        position: relative;
        margin-top: -8px;
    }
   img{
    width: 1920px;
   }
   p{
    position: absolute;
    left:670px;
    top:120px;
    font-size: 62px;
    color: #2bb7c4;
    letter-spacing:4px;
   }
   
</style>